import React from 'react'
import { Link, graphql } from 'gatsby'
import Main from '../layouts/main'
import Hero from '../components/hero'
import styles from './blog.module.scss'

const IndexPage = (props) => {
  const postList = props.data.allMarkdownRemark;
  return (
    <Main>
      <Hero
        backgroundImage='blogBackground'
        backgroundOverlay={null}
      >
        <div className={`${styles.container} ${styles.heroLarge}`}>
          <div className={styles.intro}>
            <h1 className={`${styles.fadeInOne} ${styles.heroText} ${styles.title1}`}>
              THE LATEST FROM —
            </h1>
            <h2 className={`${styles.fadeInTwo} ${styles.heroText} ${styles.title2}`}>
              ELEMENTIVE
            </h2>
          </div>
        </div>
      </Hero>
      <div className={styles.posts}>
        {postList.edges.map(({ node }, i) => (
          <div className={styles.post} key={i}>
            <Link to={`/blog/${node.frontmatter.slug}`} key={i}>
              <div className={styles.postMeta}>
                <h3>{node.frontmatter.title}</h3>
                <h4 className={styles.metaSub}>{node.frontmatter.date}</h4>
                <h4 className={styles.metaSub}>Author: {node.frontmatter.author}</h4>
                <p className={styles.contentSample}>{node.excerpt}</p>
              </div>
            </Link>
            <Link to={`/blog/${node.frontmatter.slug}`} key={`${i}_read`}>
              <button className={`${styles.button} ${styles.buttonMargin}`}>
                Read
              </button>
            </Link>
          </div>
          )
        )}
      </div>
    </Main>
  )
}

export default IndexPage;

export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields{
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            author
            slug
          }
        }
      }
    }
  }
`